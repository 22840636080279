
import { Component, Vue } from 'vue-property-decorator'
import ExpendSearchPanel from '@/frame/share/expend-search-panel.vue'
import TablePanel from '@/frame/share/table-panel.vue'
import tableConfig from './index-table.config'
import registerApi, {
  InstanceChecksEntity,
  InstanceItemEntity,
  ServiceEntity
} from '@/api/maintain/register'
import { PagerQueryBean, PageListData } from '@/libs/http-request'
import _ from 'lodash'
import AppInstanceApi, { AppInstance } from '@/api/maintain/app-instance'
import AppInstanceConfigApi from '@/api/maintain/app-instance-config'
import { BaseConfigType } from '@/api/maintain/base-config'
import { setInterval } from 'timers'
import showInitConsulModal from '@/views/config-center/templete/init-consul-modal'
import { updateConsulInstance, getterConsulInstance, getterUserInfo } from '@/store'
import moment from 'moment'
import PcServerApi from '@/api/maintain/pc-server'

const defaultQuery = {
  page: 0,
  pageSize: 0,
  sorts: [],
  nameAtApplication: undefined
}

@Component({
  name: 'dataList',
  components: { ExpendSearchPanel, TablePanel }
})
export default class extends Vue {
  // 当前状态
  loading = false;
  /// 表格定义
  tableConfig = tableConfig;
  /// 页面数据
  pageData: PageListData<ServiceEntity> = {
    items: [],
    total: 0,
    pageSize: 0
  };

  showData: ServiceEntity[] = [];
  curPage = 1;
  pageSize = 10;
  total = 10;
  entityType: Record<string, string> = {};
  // 注册中心实例选项
  apiInstance = [] as AppInstance[];
  /** 访问consul token */
  xbgManagerToken = '';
  /** 可初始化token */
  canInitToken = true;
  // 实例ip
  ip = '';
  // 端口
  port = '';

  curInstanceData = {} as AppInstance;

  firstPcServerId = 0;

  /**
   * 是否开通企业用户
   */
  get opening () {
    const user = getterUserInfo()
    return user.operaExpirationTime && moment(user.operaExpirationTime).isAfter(moment.now())
  }

  /**
   * 获取实例类型
   */
  setTypes () {
    registerApi
      .requestInstanceCheckList(this.ip, this.port, this.xbgManagerToken)
      .then((res) => {
        const checkEntitys = Object.values(res.data) as InstanceChecksEntity[]
        checkEntitys.map((e: InstanceChecksEntity) => {
          this.entityType[e.ServiceID || ''] = e.Type || ''
        })
      })
  }

  /// 查询数据
  queryData: PagerQueryBean = _.cloneDeep(defaultQuery);

  timer: any = undefined;

  initConsul () {
    showInitConsulModal(this.curInstanceData)
  }

  /** 清除无效服务 */
  cleanService () {
    registerApi
      .listCriticalServcei(this.ip, this.port, this.xbgManagerToken)
      .then((res) => {
        const services = Object.values(res.data)
        if (services.length < 1) {
          this.$Message.info('当前无需删除的无效服务')
          return
        }
        Promise.all(
          services.map((e: any) =>
            registerApi.dergisterService(
              this.ip,
              this.port,
              e.ServiceID,
              this.xbgManagerToken
            )
          )
        )
          .then(() => {
            this.$Message.success('删除无效服务成功')
            this.doPullDataList()
          })
          .catch((err) => {
            this.$Message.error(err.message)
          })
      })
  }

  /**
   * 页数变化操作
   * @param val
   */
  handlePageChange (val: any) {
    if (val.pageSize * val.curPage >= this.total) {
      this.showData = this.pageData.items.slice(val.pageSize * (val.curPage - 1))
    } else {
      this.showData = this.pageData.items.slice(
        val.pageSize * (val.curPage - 1),
        val.pageSize * val.curPage
      )
    }
  }

  created () {
    AppInstanceApi.listAppInstanceByImageType(1).then((response) => {
      this.apiInstance = response.data || []
      if (!this.opening) {
        PcServerApi.getFirstPcServerId().then((res) => {
          this.firstPcServerId = res.data || 0
          this.apiInstance = this.apiInstance.filter((e) => e.pcServerId === this.firstPcServerId)
        })
      }

      if (getterConsulInstance()?.id) {
        this.curInstanceData = getterConsulInstance()
        this.changeInstance(this.curInstanceData.id)
      }
    })
    this.timer = setInterval(() => {
      if (this.ip && this.port) {
        this.doPullDataList()
      }
    }, 30000)
  }

  activated () {
    AppInstanceApi.listAppInstanceByImageType(1).then((response) => {
      this.apiInstance = response.data || []
    })
    this.timer = setInterval(() => {
      if (this.ip && this.port) {
        this.doPullDataList()
      }
    }, 30000)
  }

  unmounted () {
    clearInterval(this.timer)
  }

  changeInstance (id: any) {
    AppInstanceApi.queryInstanceExtData(id).then((res) => {
      this.xbgManagerToken = res.data?.xbgManagerToken
      this.canInitToken = !res.data?.xbgAppToken?.match(/\S/)
      this.curInstanceData =
        this.apiInstance.find((item) => item.id === id) || ({} as AppInstance)
      // 缓存
      updateConsulInstance(this.curInstanceData)
      this.ip = this.curInstanceData?.pcServerIpAddress || ''
      /// 请求port
      AppInstanceConfigApi.query({
        page: 1,
        pageSize: 10,
        sorts: [],
        appInstanaceId: this.curInstanceData?.id,
        configType: BaseConfigType.PORT,
        configAlia: location.protocol === 'http:' ? 'http服务端口' : 'https服务端口'
      }).then((res) => {
        if (!res.data || res.data.items.length < 1) {
          this.$Message.error(
            `当前consul暂未配置${location.protocol === 'http:' ? 'http' : 'https'}端口`
          )
          return
        }
        this.port = res.data?.items[0].configValue || ''
        this.doPullDataList()
      })
    })
  }

  /**
   * 销毁定时器
   */
  deactivated () {
    clearInterval(this.timer)
  }

  /**
   * 从服务器拉取数据
   */
  doPullDataList (): void {
    if (!this.ip || !this.ip.match(/\S/)) {
      this.$Notice.warning({
        title: '警告',
        desc: '请先选择注册中心实例'
      })
      return
    }
    this.loading = true
    this.setTypes()
    registerApi
      .requestList(this.ip, this.port, this.xbgManagerToken)
      .then((res: any) => {
        // 处理搜索条件
        this.pageData.items = res.data.filter((e: ServiceEntity) => {
          if (this.queryData.nameAtApplication) {
            return (
              e.Name !== 'consul' && ~e.Name.indexOf(this.queryData.nameAtApplication)
            )
          } else {
            return e.Name !== 'consul'
          }
        })
        this.total = this.pageData.items.length
        this.pageSize = 10
        this.curPage = 1

        /// 临时变量 保存items
        let tempData = []
        if (this.pageSize * this.curPage >= this.total) {
          tempData = this.pageData.items
        } else {
          tempData = this.pageData.items.slice(0, this.pageSize)
        }
        // 判断showData有无值，有值则挨个遍历(避免整个表格刷新)，无值或者原数组长度和新数组长度不同(长度不同肯定导致外层数组刷新)时则直接赋值
        if (
          this.showData &&
          this.showData.length > 0 &&
          this.showData.length === tempData.length
        ) {
          /// 遍历整个showData
          for (let i = 0; i < this.showData.length; i++) {
            /// 先在tempData中找到对应的showData，如果没找到则删除该值
            /// 又因为删除会导致外层数组刷新 因此直接赋值
            const index = tempData.findIndex(
              (item) => this.showData[i].Name === item.Name
            )
            if (index === -1) {
              this.showData = tempData
              break
            } else if (!_.isEqual(this.showData[i], tempData[index])) {
              /// 不相同时 刷新Nodes
              Object.assign(this.showData[i], tempData[index])
              this.pullInstanceList({ row: this.showData[i] })
            }
          }
        } else {
          this.showData = tempData
        }
      })
      .catch((err: Error) => {
        this.$Notice.error({
          title: '发生错误',
          desc: '获取应用数据失败:' + err.message
        })
      })
      .finally(() => {
        this.loading = false
      })
  }

  /**
   * 懒加载拉取实例信息
   */
  pullInstanceList ({ row }: { row: ServiceEntity }) {
    return new Promise<void>((resolve) => {
      registerApi
        .requestInstanceWithIp(this.ip, row.Name, this.port, this.xbgManagerToken)
        .then((res: any) => {
          this.$set(row, 'InstanceList', res.data as Array<InstanceItemEntity>)
          resolve()
        })
        .catch((err: Error) => {
          this.$Notice.error({
            title: '发生错误',
            desc: '获取实例数据失败:' + err.message
          })
        })
    })
  }
}
