/// 表格基本配置
const options = {}

/// 导出列定义
const columns = [
  {}
]

/// 实例表格的column
const instanceColumns = [
  { width: '40' },
  { title: '状态', field: 'Checks[1].Status', width: '200' },
  { title: '名称', field: 'Service.ID', width: '300' },
  { title: 'IP地址', field: 'Service.Address', width: 'auto' }
]

/// 导出配置
export default {
  options: options,
  columns: columns,
  instanceColumns
}
