import axios from 'axios'

/**
 * host信息实体
 */
export interface HostEntity {
  Address: string;
  Port: number;
}

/**
 * 应用实体
 */
export interface ServiceEntity {
  /// 对实体状态的3种统计
  ChecksCritial: number;
  ChecksPassing: number;
  ChecksWarning: number;

  ConnectWithGateway: boolean;
  ConnectWithProxy: boolean;
  /// 所属数据中心
  Datacenter: string;
  ExternalSources?: any;
  GatewayConfig?: Record<string, any>;
  /// 实例数量统计
  InstanceCount: number;
  /// 应用名
  Name: string;
  /// 所属节点
  Nodes: Array<string>;
  Tags?: any;

  InstanceList?: Array<InstanceItemEntity>;
}

/**
 * 实例实体
 */
export interface InstanceEntity {
  ID: string;
  Service: string;
  Tags?: Array<any>;
  Address: string;
  TaggedAddress?: Record<string, HostEntity>;
  Meta?: Record<string, any>;
  Port: number;
  Weights?: {
    Passing?: number;
    Warning?: number;
    Critial?: number;
  };
  EnableTagOverride: boolean;
  Proxy?: {
    MeshGateway?: Record<string, any>;
    Expose?: Record<string, any>;
  };
  Connect?: Record<string, any>;
  CreateIndex?: number;
  ModifyIndex?: number;
  Checks?: Array<any>;
}
/**
 * 检测结果实体
 */
export interface InstanceChecksEntity{
  Node?: string;
  CheckID?: string;
  Name?: string;
  Status?: string;
  Notes?: string;
  Output?: string;
  ServiceID?: string;
  ServiceName?: string;
  ServiceTags?: Array<string>;
  Interval?: string;
  Timeout?: string;
  Type?: string;
  ExposedPort?: number;
  Definition?: any;
  Namespace?: string;
  CreateIndex?: number;
  ModifyIndex?: number;
}

/**
 * 实例状态实体
 */
export interface InstanceHealthEntity {
  Node: string;
  CheckID: string;
  Name: string;
  Status: string;
  Notes: string;
  Output: string;
  ServiceID: string;
  ServiceName: string;
  Type: string;
  Definition: Record<string, any>;
  CreateIndex: number;
  ModifyIndex: number;
}

/**
 * 根据应用获得的实例列表
 */
export interface InstanceItemEntity {
  Service: InstanceEntity;
  Checks: Array<InstanceHealthEntity>;
}

/**
 * 团队服务请求api接口
 */
class RegisterApi {
  /**
   * 拉取服务列表
   * @returns 服务列表
   */
  public requestList (ip: string, port: string, token: string): Promise<Array<ServiceEntity>> {
    return axios.request({
      url: `${location.protocol}//${ip}:${port}/v1/internal/ui/services`,
      method: 'GET',
      headers: token?.match(/\S/) ? {
        authorization: 'Bearer ' + token
      } : undefined
    })
  }

  /**
   * 根据服务拉取实例列表
   * @param serviceName 服务名称
   * @returns 实例列表
   */
  public requestInstanceWithIp (ip: string, serviceName: string, port: string, token: string) {
    return axios.request({
      url: `${location.protocol}//${ip}:${port}/v1/health/service/${serviceName}`,
      method: 'GET',
      headers: token?.match(/\S/) ? {
        authorization: 'Bearer ' + token
      } : undefined
    })
  }

  /**
   * 拉取实例检测信息
   */
  public requestInstanceCheckList (ip: string, port: string, token: string) {
    return axios.request({
      url: `${location.protocol}//${ip}:${port}/v1/agent/checks`,
      method: 'GET',
      headers: token?.match(/\S/) ? {
        authorization: 'Bearer ' + token
      } : undefined
    })
  }

  /**
   * 获取无效服务列表
   * @param ip
   * @param port
   * @param token
   */
  public listCriticalServcei (ip: string, port: string, token: string) {
    return axios.request({
      url: `${location.protocol}//${ip}:${port}/v1/health/state/critical`,
      method: 'GET',
      headers: token?.match(/\S/) ? {
        authorization: 'Bearer ' + token
      } : undefined
    })
  }

  /**
   * 删除指定id的服务注册
   * @param ip
   * @param port
   * @param serviceId
   * @param token
   */
  public dergisterService (ip: string, port: string, serviceId: string, token: string) {
    return axios.request({
      url: `${location.protocol}//${ip}:${port}/v1/agent/service/deregister/${serviceId}`,
      method: 'PUT',
      headers: token?.match(/\S/) ? {
        authorization: 'Bearer ' + token
      } : undefined
    })
  }
}

export default new RegisterApi()
